
	import VuePictureCropper, {
		cropper
	} from 'vue-picture-cropper'
	import {
		ElMessage
	} from 'element-plus'
	import {
		readFileContent,
		//getUUID
	} from "../util/index.js"
	import {
		getCookie
	} from '../util/index.js';

	export default {
		data() {
			return {
				clientWidth: document.documentElement.clientWidth,
				labelPosition: 'right',
				shotinputwidth: {
					width: '280px'
				},
				ImageUrl: '',
				form: {
					ImageUrl: '',
					zhanghao: '',
					mima: '',
					xinmima: '',
					querenmima: '',
					email: '',
					shouji: '',
					juese: '',
				},
				croperimg: '',
				showcroperdlg: false,
				loading: false,
			}
		},
		components: {
			VuePictureCropper
		},
		beforeCreate() {
			this.PAGELANG = this.LANG.pages.Security;
		},
		created() {
			window.addEventListener('resize', this.handleResize)
		},
		mounted() {
			let that = this;
			if (that.clientWidth <= 480) {
				that.labelPosition = 'top';
				that.shotinputwidth = {};
			} else {
				that.labelPosition = 'right';
				that.shotinputwidth = {
					width: '280px'
				};
			}
			
			this.refreshPageInfo();
		},
		methods: {
			handleResize() {
				this.clientWidth = document.documentElement.clientWidth;
			},
			onFileSel(filelist) {
				if (filelist.length <= 0)
					return;

				let file = filelist[0];

				if (!file.name)
					return;
					
				this.CropperOpt = 1;

				var index = file.name.lastIndexOf("."); //获取最后一个.的位置
				var ext = file.name.substr(index + 1).toLowerCase(); //获取后缀
				var pattern = /^(jpg|jpeg|png|gif)$/;
				if (!pattern.test(ext)) {
					ElMessage({
						message: this.PAGELANG.wjgsbzc,
						type: 'error',
					});
					return false;
				}

				readFileContent(file, 'dataUrl').then((contents) => {
					this.croperimg = contents;
					this.showcroperdlg = true;
					this.$refs.uploader.value = '';
				});

			},
			CropImage() {
				if(!this.showcroperdlg)
					return;
					
				this.showcroperdlg = false;
				this.form.ImageUrl = cropper.getDataURL();
				this.ImageUrl = this.form.ImageUrl;
			},
			onExecCrop() {
				this.imageurl = cropper.getDataURL();
				this.showimgeditor = false;
				this.himgupdated = true;
			},
			refreshPageInfo() {
				if(this.loading) {
					ElMessage({
						message: this.LANG.system.xitongfanmang,
						type: "warning"
					})
					return;
				}
				let loginopid = getCookie('loginopid');
				if(!loginopid)
					return;
				
				this.loading = true;				
				this.axios.get(this.actions.getopinfo, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					params: {
						id: loginopid
					}
				}).then((response) => {
					console.log(response);
					this.loading = false;
					let uinfo = response.data;
					this.form.zhanghao = uinfo.OpName;
					this.form.email = uinfo.Email;
					this.form.shouji = uinfo.OpMobile;
					this.form.juese = uinfo.Role;
					this.form.ImageUrl = uinfo.HeadImage;
					if(this.form.ImageUrl)
						this.ImageUrl = this.apibase + this.form.ImageUrl;
				});

			},
			onSubmit() {
				/*this.axios.get("/api/api.sample.js").then((response) => {
					if (response.status == 200)
						console.log(response.data);
				})*/
				if(this.loading) {
					ElMessage({
						message: this.LANG.system.xitongfanmang,
						type: "warning"
					})
					return;
				}
				console.log(this.form);
				this.loading = true;
				this.axios.post(this.actions.saveopinfo, this.form).then((response) => {
					console.log(response);
					if (response.data.retinfo == 'ok') {
						ElMessage({
							message: this.LANG.system.chenggonggengxinshuju,
							type: "success"
						});
						console.log('onHeadImageChanged');
						this.$emit("onHeadImageChanged", this.ImageUrl);
					}
					else {
						let errortxt = this.PAGELANG[response.data.retinfo];
						if(!errortxt)
							errortxt = this.LANG.system.gengxinshujucuowu;
						ElMessage({
							message: errortxt,
							type: "error"
						});
					}
					this.loading = false;
				});
			},
			onCancel() {
				this.$router.push('/');
			}
		},
		watch: {
			clientWidth(val) {
				// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
				if (!this.timerw) {
					// 一旦监听到的clientWidth值改变，就将其重新赋给data里的clientWidth
					this.clientWidth = val
					this.timerw = true
					let that = this
					setTimeout(function() {
						// 打印clientWidth变化的值
						if (that.clientWidth <= 480) {
							that.labelPosition = 'top';
							that.form.shotinputwidth = {};
						} else {
							that.labelPosition = 'right';
							that.form.shotinputwidth = {
								width: '280px'
							};
						}
						console.log(that.labelPosition + '-' + that.clientWidth)
						that.timerw = false
					}, 400)
				}
			},
		}
	}
